import gjsBlockBasic from "grapesjs-blocks-basic";
import Carousel from "./Common/carousel";
import FeatureSection from "./Common/feature-section";

import Navbar from "./Common/navbar";
import Footer from "./Common/footer";
import Form from "./Common/form";
import ButtonA from "./Common/buttonA";
import Button from "./Common/button";

import UserCart from "./Ecommerce/user-cart";
import UserCheckoutForm from "./Ecommerce/user-checkout-form";

import ProductDetail from "./Ecommerce/productDetail";
import ProductList from "./Ecommerce/productList";
import ProductCategory from "./Ecommerce/productCategory";

import BlogList from "./Blog/blogList";
import BlogCategory from "./Blog/blogCategory";
import BlogDetail from "./Blog/blogDetail";

import Icons from "./Icons";

import WebNavbar from "./Online Ordering/Navbar";
import WebFooter from "./Online Ordering/Footer";
import ProductCard from "./Online Ordering/Card";
import Checkout from "./Online Ordering/CheckOut";
import SearchBox from "./Online Ordering/SearchBox";
import Cart from "./Online Ordering/Cart";

import ProductDetails from "./Online Ordering/ProductDetail";

export default [
  gjsBlockBasic,
  Carousel,
  FeatureSection,
  Navbar,
  Footer,
  Form,
  Button,
  ButtonA,
  UserCart,
  UserCheckoutForm,
  ProductDetail,
  ProductCategory,
  ProductList,
  BlogDetail,
  BlogList,
  BlogCategory,
  ...Icons,
  WebNavbar,
  WebFooter,
  ProductCard,
  Checkout,
  SearchBox,
  Cart,
  ProductDetails,
];
