export default (editor, opts = {}) => {
  const doc = editor.DomComponents;
  const primaryColor = "#58AA46";
  const whiteColor = "#ffffff";
  const blackColor = "#4A4A4A";
  const borderColor = "#EDEDED";
  const secondary = "#F4F4F4";

  doc.addType("PRODUCT_DETAIL-COMPONENT", {
    model: {
      toHTML() {
        return `
        <style>
         .cmp-css-modifier-category-price{
           color: ${blackColor};
          }
          .toast {
           visibility: hidden;
            min-width: 200px;
            margin-right: 20px;
            background-color: #58AA46;
            color: #ffffff;
            text-align: center;
            position: fixed;
            z-index: 2;
            right: 0;
            top: 20px;
            font-size: 17px;
            white-space: nowrap;
            padding: 15px 10px;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
        }
        .toast.show {
           visibility: visible;
           opacity: 1;
           transition: opacity 0.5s ease-in-out
        }
        
        </style>
          <div class="cmp-css-container-details">
            <div class="cmp-css-imagefield"></div>
            <div class="cmp-css-card"></div>
          </div>
          <div id="toast" class="toast"></div>
         
    <script>
    document.addEventListener("DOMContentLoaded", function () {
      const origin = window.location.origin;

      function showToast(message, href ="") {
          const toast = document.getElementById("toast");
          if (toast) {
           toast.textContent = message;
           toast.classList.add("show");
        setTimeout(() => {
            toast.classList.remove("show");
             if(href !== ""){
              window.location.href = href;
             }
           }, 1300);
          } else {
          console.error('Toast element not found');
         }
        }

        const addToCart = async (productValue) => {
          try {
            let url = origin + "/api/v1/cart/add-item/"
            const response = await fetch(url, {
               method: 'POST',
               headers: {
               'Accept': 'application/json',
               'Content-Type': 'application/json'
                },
               body: JSON.stringify(productValue)
            });
            if (!response.ok) {
              throw new Error("Network response was not ok");
            };
            const product = await response.json();
            showToast("Item added successfull in Cart", "/")
            return product;

          } catch (error) {
            console.log("error", error);
            showToast(error.message)
          }
            
        };
      const productListCategory = async (categoryId = "") => {
          try {
            let url = origin + "/api/v1/products/?category=" + categoryId;
            const response = await fetch(url,{
              method: 'GET',
              mode: 'no-cors',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            const data = await response.json();
            const categoryArray = [];
             data.forEach((product) => {
                product?.category?.forEach((cat) => {
                   const categoryName = cat?.name;
                   if (!categoryArray[categoryName]) {
                     categoryArray[categoryName] = [];
                   }
                  categoryArray[categoryName].push(product);
                });
            });
            const productListContainer = document.querySelector(".cmp-css-container-details");
        
            Object.keys(categoryArray)?.map((categoryName)=>{
              const categoryTitle = document.createElement("div");
              categoryTitle.classList.add("cmp-css-categorytitle");
              categoryTitle.textContent = "More Similar Items";
              productListContainer.appendChild(categoryTitle);

              const cardGroup = document.createElement("div");
              cardGroup.classList.add("cmp-css-cards-list");
              
              categoryArray[categoryName]?.map((product)=>{
                const singleCards = document.createElement("div");
                singleCards.classList.add("cmp-css-single-card");
                product.category.forEach((category) => {
                categoryTitle.id = categoryId;
                singleCards.id = categoryId;
              }); 
               
               singleCards.addEventListener("click", ()=>{
                window.location.href = "/product/" + product.pk  ;
              })

                const cardDetails = document.createElement("div");

                const productTitle = document.createElement("div");
                productTitle.classList.add("cmp-css-single-title");
                productTitle.textContent = product.title;
               
                const productSellingPrice = document.createElement("div");
                productSellingPrice.classList.add("cmp-css-single-subtitle");
                const [integerPart, decimalPart] = Number(product.selling_price).toFixed(2).split('.');
                const integerSpan = document.createElement("span");
                integerSpan.style.fontSize = "18px"; 
                integerSpan.innerHTML = "$" + integerPart;

                const decimalSpan = document.createElement("span");
                decimalSpan.style.fontSize = "14px";  
                decimalSpan.innerHTML = "." + decimalPart;

                productSellingPrice.appendChild(integerSpan);
                productSellingPrice.appendChild(decimalSpan);  

                const productDesc = document.createElement("div");
                productDesc.classList.add("cmp-css-single-paragraph");
                if (typeof product.short_desc === "string") {
                  productDesc.textContent =
                    product?.short_desc ? product.short_desc.slice(0, 100) + "..." : "";
                } else {
                  productDesc.textContent = "";
                }

                const imageField = document.createElement("div");
                imageField.classList.add("cmp-css-single-imagefield");

                if (product.image) {
                  const productImage = document.createElement("img");
                  productImage.classList.add("cmp-css-single-img");
                  productImage.src = product.image;
                  imageField.appendChild(productImage);
                }else{
                  const emptyImage = document.createElement("div");
                  emptyImage.classList.add("cmp-css-single-img");
                  imageField.appendChild(emptyImage);
                }

                const redirectButton = document.createElement("a");
                redirectButton.classList.add("cmp-css-redirect-add");
                redirectButton.textContent = "+";
                redirectButton.href = "/product/" + product.pk ;


                cardDetails.appendChild(productTitle);
                cardDetails.appendChild(productSellingPrice);
                cardDetails.appendChild(productDesc);

                imageField.appendChild(redirectButton);

                singleCards.appendChild(imageField);
                singleCards.appendChild(cardDetails);
                cardGroup.appendChild(singleCards);
              });
              productListContainer.appendChild(cardGroup);
            })
            
          } catch (error) {
            console.error("Error:", error);
          }
        };

        const singleProductList = async (productId) => {
          try {
            let url = origin + "/api/v1/products/";
             if(productId){
              url += productId;
             }
            const response = await fetch(url,{
              method: 'GET',
              mode: 'no-cors',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }

            const product = await response.json();

            product.category.forEach((item)=>{
              productListCategory(item.id)
            });

            const produtDetailContainer = document.querySelector(".cmp-css-container-details");

            produtDetailContainer.innerHTML = "";
            produtDetailContainer.style.display = "none";

            const imageField = document.createElement("div");
            imageField.classList.add("cmp-css-imagefield");

            if(product.image){
              const productImage = document.createElement("img");
              productImage.classList.add("cmp-css-img");
              productImage.src = product.image ;
              imageField.appendChild(productImage);
            }

            const closeButton = document.createElement("a");
            closeButton.classList.add("cmp-css-close-details");
            closeButton.innerHTML = "&#10006";
            closeButton.href = "/"

            imageField.appendChild(closeButton);

            const productCard = document.createElement("div");
            productCard.classList.add("cmp-css-card"); 
            
            const productDetail = document.createElement("div");
            productDetail.classList.add("cmp-css-product-details"); 

            const productTitle = document.createElement("div");
            productTitle.classList.add("cmp-css-title");
            productTitle.textContent = product.title;
            productDetail.appendChild(productTitle);

            const productSellingPrice = document.createElement("div");
            productSellingPrice.classList.add("cmp-css-subtitle");
            const [integerPart, decimalPart] = Number(product.selling_price).toFixed(2).split('.');
            const integerSpan = document.createElement("span");
            integerSpan.style.fontSize = "18px"; 
            integerSpan.innerHTML = "$" + integerPart;

            const decimalSpan = document.createElement("span");
            decimalSpan.style.fontSize = "14px";  
            decimalSpan.innerHTML = "." + decimalPart;

            productSellingPrice.appendChild(integerSpan);
            productSellingPrice.appendChild(decimalSpan);  
            productDetail.appendChild(productSellingPrice);

            const productDesc = document.createElement("div");
            productDesc.classList.add("cmp-css-paragraph");
            productDesc.textContent = product.short_desc;
            productDetail.appendChild(productDesc);

            product.category.forEach((category) => {
              const categoryDiv = document.createElement("span");
              categoryDiv.classList.add("cmp-css-category");
              categoryDiv.textContent = category.name;
              productDetail.appendChild(categoryDiv);
            }); 
            let modifierCategoriesList = [];

            const modifierCategoriesContainer = document.createElement("div");
            modifierCategoriesContainer.classList.add("cmp-css-modifier-category-container");
            
            product.modifier_category.forEach((categories) => {
              const modifierCategoriesInner = document.createElement("div");
              modifierCategoriesInner.classList.add("cmp-css-modifier-category-inner");

              const modifierCategoriesTitle = document.createElement("div");
              modifierCategoriesTitle.classList.add("cmp-css-modifier-category-title");
              modifierCategoriesTitle.textContent = categories.name;
              modifierCategoriesInner.appendChild(modifierCategoriesTitle);
              
              categories?.modifiers?.forEach((modifier) =>{
                const modifierCategoriesDiv = document.createElement("div");
                modifierCategoriesDiv.classList.add("cmp-css-modifier-category-div");
              
                const modifierCategoryCheckbox = document.createElement("input");
                modifierCategoryCheckbox.classList.add("cmp-css-modifier-category-checkbox");
                modifierCategoryCheckbox.setAttribute("type", "checkbox");
                modifierCategoryCheckbox.id = modifier.id;

                const modifierCategoryName = document.createElement("span");
                modifierCategoryName.classList.add("cmp-css-modifier-category-name");
                modifierCategoryName.textContent = modifier.title;

                const modifierCategoryPrice = document.createElement("span");
                modifierCategoryPrice.classList.add("cmp-css-modifier-category-price");
                modifierCategoryPrice.textContent = "- $" + modifier.selling_price;

                modifierCategoriesDiv.appendChild(modifierCategoryCheckbox);
                modifierCategoriesDiv.appendChild(modifierCategoryName);
                modifierCategoriesDiv.appendChild(modifierCategoryPrice);
                modifierCategoriesInner.appendChild(modifierCategoriesDiv);

                modifierCategoryCheckbox.addEventListener('click', function(event) {
                  event.stopPropagation();

                  if (modifierCategoryCheckbox.checked) {
                    if (!modifierCategoriesList.includes(modifierCategoryCheckbox.id)) {
                      modifierCategoriesList.push(modifierCategoryCheckbox.id);
                    }
                  } else {
                    modifierCategoriesList = modifierCategoriesList.filter(id => id !== modifierCategoryCheckbox.id);
                  }
                });

                modifierCategoriesDiv.addEventListener('click', function() {
                  modifierCategoryCheckbox.checked = !modifierCategoryCheckbox.checked;
            
                  if (modifierCategoryCheckbox.checked) {
                    if (!modifierCategoriesList.includes(modifierCategoryCheckbox.id)) {
                      modifierCategoriesList.push(modifierCategoryCheckbox.id);
                    }
                  } else {
                    modifierCategoriesList = modifierCategoriesList.filter(id => id !== modifierCategoryCheckbox.id);
                  }
                });
             });
             modifierCategoriesContainer.appendChild(modifierCategoriesInner);
              
            });
            productDetail.appendChild(modifierCategoriesContainer);

            const divider = document.createElement("div");
            divider.classList.add("cmp-css-divider");
            productDetail.appendChild(divider);

            const instructionText = document.createElement("p");
            instructionText.classList.add("cmp-css-specialinst");
            instructionText.textContent = "Special Instructions";
            productDetail.appendChild(instructionText);

            const textarea = document.createElement("textarea");
            textarea.setAttribute("name", "default-name");
            textarea.setAttribute("placeholder", "Note goes here...");
            textarea.classList.add("css-cmp-cardinput");
            productDetail.appendChild(textarea);

            const quantityContainer = document.createElement("div");
            quantityContainer.classList.add("cmp-css-product-quantityContainer");

            const quantityButtons = document.createElement("div");
            quantityButtons.classList.add("cmp-css-product-detail-right");

            const decrementButton = document.createElement("span");
            decrementButton.classList.add("cmp-css-cart-button");
            decrementButton.id = "decrement";
            decrementButton.innerHTML = " &#8722; ";
            quantityButtons.appendChild(decrementButton);

            const valueDisplay = document.createElement("span");
            valueDisplay.id = "value";
            valueDisplay.textContent = 1;
            quantityButtons.appendChild(valueDisplay);

            const incrementButton = document.createElement("span");
            incrementButton.classList.add("cmp-css-cart-button");
            incrementButton.id = "increment";
            incrementButton.textContent = " + ";
            quantityButtons.appendChild(incrementButton);

            quantityContainer.appendChild(quantityButtons);

            let currentValue = 1;

            decrementButton.addEventListener("click", () => {
              if(currentValue > 1){
                currentValue--;
                valueDisplay.textContent = currentValue;
                updateAddToCartButton();
              }
            });
            incrementButton.addEventListener("click", () => {
              currentValue++;
              valueDisplay.textContent = currentValue;
              updateAddToCartButton();
            });

            const text = document.createElement("text");
            text.classList.add("css-cmp-qtntext");
            text.textContent = "You may be charged for extras.";
            quantityContainer.appendChild(text);

            productDetail.appendChild(quantityContainer);

            const dividers = document.createElement("div");
            dividers.classList.add("cmp-css-divider");
            productDetail.appendChild(dividers);

            let noteValue ;
            textarea.addEventListener("change", (event) => {
              noteValue = event.target.value;
            });

            function updateAddToCartButton() {
             let productPrices = Number(currentValue) * Number(product?.selling_price);
             addToCartButton.textContent = "Add" + " " + "(" + currentValue + ")" + " " + "-" + " " +"$" + parseFloat(productPrices).toFixed(2);
            }
            
            const addToCartView = document.createElement("div");
            addToCartView.classList.add("cmp-css-view-cart");
            const addToCartButton = document.createElement("a");
            addToCartButton.classList.add("cmp-css-view-button");

            addToCartView.appendChild(addToCartButton);
            updateAddToCartButton();


            addToCartButton.addEventListener("click", async(event) => {
                event.preventDefault();
              const viewCartButton = document.querySelector(".cmp-css-view-cart a.cmp-css-view-button");
              if (viewCartButton) {
                 viewCartButton.disabled = true;
               }
              const modifierData = [];
              modifierCategoriesList?.forEach((id)=>{
                 modifierData.push({
                   product_id: id,
                   quantity: "1",
                 })
               })
              const productValue = {
                 "product_id": productId,
                 "quantity": currentValue ?? "1",
                 "note": noteValue ?? "",
                 "modifiers": modifierData ?? []
               } 
              await addToCart(productValue);
              
            });

            produtDetailContainer.appendChild(imageField);
            
            productCard.appendChild(productDetail);
            productCard.appendChild(addToCartView);
            produtDetailContainer.appendChild(productCard);
            produtDetailContainer.style.display = "block";

          } catch (error) {
            console.error("Error:", error);
          }
        };
        const pathname = window.location.pathname;
        const segments = pathname.split('/');
        const id = segments[2]; 
        singleProductList(id);

      });
        </script>
        
        `;
      },

      defaults: {
        attributes: { class: "cmp-css-container-details" },
        copyable: false,
        removable: false,
        droppable: false,
        editable: false,
        components: [
          {
            attributes: { class: "toast", id: "toast" },
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
          },
          {
            type: "div",
            draggable: false,
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            attributes: { class: "cmp-css-imagefield" },
            components: [
              {
                type: "span",
                draggable: false,
                copyable: false,
                removable: false,
                editable: false,
                content: `<svg xmlns="http://www.w3.org/2000/svg" width="150" viewBox="0 0 24 24" style="fill: rgba(0,0,0,0.15);transform: scale(0.75)"><path d="M2.28 3L1 4.27l2 2V19c0 1.1.9 2 2 2h12.73l2 2L21 21.72 2.28 3m2.55 0L21 19.17V5a2 2 0 0 0-2-2H4.83M8.5 13.5l2.5 3 1-1.25L14.73 18H5l3.5-4.5z"></path></svg>`,
                attributes: {
                  class: "cmp-css-img",
                },
              },
              {
                type: "span",
                draggable: false,
                copyable: false,
                removable: false,
                editable: false,
                content: `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <path d="M1.05033 10.9497L6.00008 6M6.00008 6L10.9498 1.05025M6.00008 6L1.05033 1.05025M6.00008 6L10.9498 10.9497" stroke="#4A4A4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              `,
                attributes: {
                  class: "cmp-css-close-details",
                },
              },
            ],
          },
          {
            draggable: false,
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            attributes: { class: "cmp-css-card" },
            components: [
              {
                type: "div",
                draggable: false,
                copyable: false,
                removable: false,
                droppable: false,
                editable: false,
                attributes: { class: "cmp-css-product-details" },

                components: [
                  {
                    type: "text",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    content: "Crispy Chicken Burger",
                    attributes: { class: "cmp-css-title" },
                  },
                  {
                    type: "text",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    content: "$15.99",
                    attributes: { class: "cmp-css-subtitle" },
                  },
                  {
                    type: "text",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    attributes: { class: "cmp-css-paragraph" },
                    content:
                      " crispy chicken fillet, cheese, tomatoes, lettuce, signature sauce on a milky brioche bun",
                  },
                  {
                    type: "text",
                    draggable: false,
                    removable: false,
                    copyable: false,
                    editable: false,
                    droppable: false,
                    content: "Burger",
                    attributes: { class: "cmp-css-category" },
                  },
                  {
                    type: "text",
                    draggable: false,
                    removable: false,
                    copyable: false,
                    editable: false,
                    droppable: false,
                    content: "Burger Meals",
                    attributes: { class: "cmp-css-category" },
                  },
                  {
                    type: "div",
                    draggable: false,
                    removable: false,
                    copyable: false,
                    editable: false,
                    droppable: false,
                    attributes: {
                      class: "cmp-css-modifier-category-container",
                    },
                    components: [
                      {
                        type: "div",
                        draggable: false,
                        removable: false,
                        copyable: false,
                        editable: false,
                        droppable: false,
                        attributes: {
                          class: "cmp-css-modifier-category-inner",
                        },
                        components: [
                          {
                            type: "text",
                            draggable: false,
                            removable: false,
                            copyable: false,
                            editable: false,
                            droppable: false,
                            content: "Modifier",
                            attributes: {
                              class: "cmp-css-modifier-category-title",
                            },
                          },
                          {
                            type: "div",
                            draggable: false,
                            removable: false,
                            copyable: false,
                            editable: false,
                            droppable: false,
                            attributes: {
                              class: "cmp-css-modifier-category-div",
                            },
                            components: [
                              {
                                tagName: "input",
                                draggable: false,
                                removable: false,
                                copyable: false,
                                editable: false,
                                droppable: false,
                                attributes: {
                                  type: "checkbox",
                                  class: "cmp-css-modifier-category-checkbox",
                                },
                              },
                              {
                                type: "span",
                                draggable: false,
                                removable: false,
                                copyable: false,
                                editable: false,
                                droppable: false,
                                content: "Onion - $2.00",
                                attributes: {
                                  class: "cmp-css-modifier-category-name",
                                },
                              },
                            ],
                          },
                          {
                            type: "div",
                            draggable: false,
                            removable: false,
                            copyable: false,
                            editable: false,
                            droppable: false,
                            attributes: {
                              class: "cmp-css-modifier-category-div",
                            },
                            components: [
                              {
                                tagName: "input",
                                draggable: false,
                                removable: false,
                                copyable: false,
                                editable: false,
                                droppable: false,
                                attributes: {
                                  type: "checkbox",
                                  class: "cmp-css-modifier-category-checkbox",
                                },
                              },
                              {
                                type: "span",
                                draggable: false,
                                removable: false,
                                copyable: false,
                                editable: false,
                                droppable: false,
                                content: "Lettuce - $2.00",
                                attributes: {
                                  class: "cmp-css-modifier-category-name",
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    tagname: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    attributes: { class: "cmp-css-divider" },
                  },
                  {
                    type: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    components: [
                      {
                        type: "num",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        content: "Special instructions",
                        attributes: { class: "cmp-css-specialinst" },
                      },
                      {
                        tagName: "textarea",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        attributes: {
                          type: "text",
                          name: "default-name",
                          placeholder: "Note goes here...",
                          class: "css-cmp-cardinput",
                        },
                      },
                    ],
                  },
                  {
                    type: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    attributes: { class: "cmp-css-product-quantityContainer" },
                    components: [
                      {
                        tagName: "div",
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        attributes: {
                          class: "cmp-css-product-detail-right",
                        },
                        components: [
                          {
                            tagName: "button",
                            copyable: false,
                            removable: false,
                            droppable: false,
                            editable: false,
                            attributes: { class: "cmp-css-cart-button" },
                            content: "-",
                          },
                          {
                            tagName: "text",
                            copyable: false,
                            removable: false,
                            droppable: false,
                            editable: false,
                            content: "1",
                          },
                          {
                            tagName: "button",
                            copyable: false,
                            removable: false,
                            droppable: false,
                            editable: false,
                            attributes: { class: "cmp-css-cart-button" },
                            content: "+",
                          },
                        ],
                      },
                      {
                        tagName: "text",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        content: "You may be charged for extras.",
                        attributes: {
                          class: "css-cmp-qtntext",
                        },
                      },
                    ],
                  },
                  {
                    type: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    attributes: { class: "cmp-css-view-cart" },
                    components: [
                      {
                        type: "div",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        attributes: { class: "cmp-css-view-button" },
                        content: "Add (1) order - $15.99",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "div",
            draggable: false,
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            content: "More Similar Items",
            attributes: { class: "cmp-css-categorytitle" },
          },
          {
            tagName: "div",
            draggable: false,
            copyable: false,
            removable: false,
            droppable: false,
            editable: false,
            attributes: { class: "cmp-css-cards-list" },
            components: [
              {
                tagName: "div",
                draggable: false,
                copyable: false,
                removable: false,
                droppable: false,
                editable: false,
                attributes: { class: "cmp-css-single-card" },
                components: [
                  {
                    tagName: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    attributes: { class: "cmp-css-single-imagefield" },
                    components: [
                      {
                        type: "div",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        editable: false,
                        content: `<svg xmlns="http://www.w3.org/2000/svg" width="200" viewBox="0 0 24 24" style="fill: rgba(0,0,0,0.15); transform: scale(0.75)">
                        <path d="M2.28 3L1 4.27l2 2V19c0 1.1.9 2 2 2h12.73l2 2L21 21.72 2.28 3m2.55 0L21 19.17V5a2 2 0 0 0-2-2H4.83M8.5 13.5l2.5 3 1-1.25L14.73 18H5l3.5-4.5z"/>
                       </svg>`,
                      },
                      {
                        tagName: "button",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        content: "+",
                        attributes: {
                          class: "cmp-css-redirect-add",
                        },
                      },
                    ],
                  },
                  {
                    tagName: "div",
                    draggable: false,
                    copyable: false,
                    removable: false,
                    droppable: false,
                    editable: false,
                    components: [
                      {
                        type: "text",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        content: "Crispy Chicken Burger",
                        attributes: { class: "cmp-css-single-title" },
                      },
                      {
                        type: "text",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        content: "$15.99",
                        attributes: { class: "cmp-css-single-subtitle" },
                      },
                      {
                        type: "text",
                        draggable: false,
                        copyable: false,
                        removable: false,
                        droppable: false,
                        editable: false,
                        attributes: { class: "cmp-css-single-paragraph" },
                        content:
                          " crispy chicken fillet, cheese, tomatoes, lettuce, signature sauce on a milky brioche bun",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],

        styles: `
        * {
         font-family: Arial, Helvetica, sans-serif;
         }
        body{
          background-color: #F5F5F5
        }
        .cmp-css-container-details{
          display: flex;
          flex-direction: column;
          gap: 10px
        }
        .cmp-css-imagefield{
          display: flex;
          poition: relative;
          align-items: center;
          justify-content: center;
        }
        .cmp-css-img{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          object-fit: cover;
        }
        .cmp-css-close-details {
           text-decoration: none;
           color: ${blackColor};
           background-color: #F5F3F3;
           border-radius: 50px;
           position: absolute;
           top: 15px;
           right: 15px;
           padding: 7px 12px 7px;
           cursor: pointer;
           margin: auto
        }
        .cmp-css-card{
          height: auto;
        }
        .cmp-css-product-details{
          padding:10px;
        }
        .cmp-css-title{
          color: ${blackColor};
          font-size: 22px;
          font-weight: bold;
          margin: 20px 0 10px 0;
        }
        .cmp-css-subtitle{
          font-weight: bold;
          font-size: 18px;
          color: ${blackColor};
          margin-bottom: 5px 
      }
        .cmp-css-paragraph{
          line-height: 1.5;
          margin-bottom: 5px;
          color: ${blackColor};
        }
        .cmp-css-divider{
          width:100%;
          border: 1px solid ${borderColor};
          margin: 10px 0;
        }
        .cmp-css-specialinst{
          font-weight: bold;
          font-size: 18px;
          color: ${blackColor};
          margin: 10px 0;
        }
        .css-cmp-cardinput{
          width: 100%;
          resize: none;
          padding: 10px;
          outline: none;
          border: none;
          border-radius: 4px;
          background-color: ${borderColor}
        }

          
        .cmp-css-qtn{
          margin: 10px 0;
          display: flex;
          gap: 10px
        }
        .cmp-css-productqtn{
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background-color: ${borderColor};
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }
        .css-cmp-qtnvalue{
          background-color: ${borderColor};
          padding: 5px 10px;
          border-radius: 50px
        }
        .css-cmp-qtntext{
          margin: auto 0;
        }
       
        .cmp-css-view-cart{
          position: fixed;
          bottom: 0;
          z-index: 2;
          width:100% ;
          background-color: white;
          box-shadow: 0px -18px 28px 0px #00000040;
          padding: 10px
        }
        .cmp-css-view-button{
          display: block;
          text-decoration: none;
          background-color: ${primaryColor};
          text-align: center;
          padding: 10px;
          color: ${whiteColor}
        }
         .cmp-css-product-quantityContainer{
          display: flex;
          gap: 10px;
          margin: 10px 0;
        }
        .cmp-css-product-detail-right{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          background: ${borderColor};
          border-radius: 20px;
          padding: 3px 5px;
          width: 60px;
          height: 100%
         }
        .cmp-css-cart-button{
          background-color: transparent;
          outline: none;
          border: none;
          font-size: 18px;
          color: ${blackColor};
          margin: auto 0;
          font-weight: 600;
          padding: 0 3px;
        }
        .cmp-css-category{
          display: inline-block;
          background-color: ${borderColor};
          text-decoration: none;
          white-space: nowrap;
          cursor: pointer;
          padding: 8px 14px;
          border-radius: 4px;
          margin: 10px 5px 10px 0;
        }
        .cmp-css-categorytitle {
          position: sticky;
          top: 0px;
          z-index: 1;
          background-color: ${whiteColor};
          border: 2px solid ${borderColor};
          color: ${blackColor};
          font-size: 20px;
          font-weight: 700;
          margin: 10px 0px;
          border-radius: 5px;
          padding: 10px;
          margin: 10px;
        }
        .cmp-css-cards-list{
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 0 10px 70px;
        }
        
        .cmp-css-single-card{
          display:flex; 
          justify-content: space-between;
          flex-direction: row-reverse;
          gap: 5px;
          height: auto;
          padding: 10px;
          border-radius: 5px;
          border: 2px solid ${borderColor};
          background-color: ${whiteColor}
        }
        .cmp-css-single-title{
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 5px;
          word-wrap: break-word;
          white-space: normal;
          color: ${blackColor};
        }
        .cmp-css-single-subtitle{
          font-weight: 600;
          margin-bottom: 5px ;
          color: ${blackColor};

        }
        .cmp-css-single-paragraph{
          margin-bottom: 5px;
          color: ${blackColor};
          line-height: 1.5;
        }
        .cmp-css-single-imagefield{
          position: relative;
          margin: auto 0
        }
       .cmp-css-single-imagefield svg{
          width: 120px;
        }
        .cmp-css-single-imagefield .cmp-css-single-img{
          width:100px;
          height: 100px;
          object-fit: cover;
          position: relative;
        }
        .cmp-css-redirect-add{
          color: ${blackColor};
          display: block;
          position: absolute;
          text-decoration: none;
          bottom: 10px;
          right: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          background-color: ${whiteColor};
          box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-weight: bold;
          border: none;
          color: ${blackColor};
          cursor: pointer
        }
       .cmp-css-modifier-category-container{
           margin: 15px 0 ;
        }
        .cmp-css-modifier-category-title{
          color: ${blackColor};
          font-size: 18px;
          font-weight: 600;
        }
        .cmp-css-modifier-category-checkbox {
          accent-color: green;
        }
        .cmp-css-modifier-category-inner{
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin: 15px 0 ;
         }
        .cmp-css-modifier-category-name{
           padding-left: 5px;
           color: ${blackColor};
        }
       
        .cmp-css-modifier-category-div{
            display: flex;
            align-items: center;
            cursor: pointer
        }
       

        `,
      },
      view: {},
    },
  });
};
